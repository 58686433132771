<template>
  <v-app>
    <Header title="Seite nicht gefunden" />
    <v-main>
      Seite nicht gefunden
      <div> <router-link to="/">Zurück</router-link></div>
    </v-main>
    <Footer />
    <AlertDialog />
  </v-app>
</template>
<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import AlertDialog from "@/components/AlertDialog.vue";

export default {
  name: "NotFound",
  components: {
    Header,
    Footer,
    AlertDialog
  },
};
</script>